@import url('https://use.typekit.net/kph8cux.css');
@import "react-toastify/dist/ReactToastify.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 768px) {
  .dbimgcont {
    max-width: 1000px;
  }

  .dbimg {
    max-width: 374px;
  }

  .wccard {
    max-width: 374px;
  }
}

@media screen and (min-width: 1024px) {
  .dbimgcont {
    max-width: 1000px;
  }

  .dbimg {
    max-width: 374px;
  }

  .wccard {
    max-width: 323px;
  }
}

@media screen and (min-width: 1280px) {
  .dbimgcont {
    max-width: 100%;
  }

  .dbimg {
    max-width: 416px;
  }

  .wccard {
    max-width: 416px;
  }
}

@media screen and (min-width: 1536px) {
  .dbimgcont {
    max-width: 100%;
  }

  .dbimg {
    max-width: 494px;
  }

  .wccard {
    max-width: 370px;
  }
}

/* numbered steps */
span.step {
  background: #12678f;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.7em;
}

/* recharts tooltip styling */

.recharts-tooltip-wrapper .custom-tooltip {
  background-color: hsla(0, 0%, 100%, .8);
  border: 1px solid #3b3939;
  line-height: 24px;
  margin: 0;
  padding: 10px;
  width: 200px
}

.recharts-tooltip-wrapper .custom-tooltip .label {
  color: #666;
  margin: 0
}

.leaflet-container {
  display: block;
  position: relative;
}

.leaflet-control-attribution {
  display: none;
}

.btn {
  background-color: #12678f;
  color: white;
}

.btn:hover {
  background-color: #0E5272 !important;
  color: white;
}

.btn:disabled {
  background-color: #5C96B0;
  color: white;
}

.btn-card {
  background-color: #E5EFF3;
  color: #3F3F3F;
}

.btn-card:hover {
  background-color: #A6C5D4;
  color: #3F3F3F;
}

.btn-card:disabled {
  background-color: #5C96B0;
  color: white;
}

.select__input:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}

.sensor-type-icon .fa-tree-deciduous g path {
  stroke: black;
  stroke-width: 10;
}

.sensor-type-icon .fa-tree g path {
  stroke: black;
  stroke-width: 10;
}
